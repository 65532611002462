<script setup>
const props = defineProps({
  expanded: {
    type: Boolean,
    default: false
  }
});

const overflowHidden = ref(true);

const onTransitionEnd = () => {
  if(props.expanded) overflowHidden.value = !props.expanded;
}

watch(
  () => props.expanded,
  (newValue) => {
    if(newValue === false) {
      overflowHidden.value = true;
    }
  });
</script>

<template>
  <div class="grid grid-rows-[0fr] transition-[grid-template-rows] duration-300 ease-out"
       :class="{'grid-rows-[1fr]': props.expanded}"
       @transitionend="onTransitionEnd"
  >
    <div :class="[{'overflow-hidden':overflowHidden}]">
      <slot />
    </div>
  </div>
</template>
