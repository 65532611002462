<script setup>

const props = defineProps({
  labelToggled: String,
  withChevron: Boolean
});

const slots = defineSlots();

const emits = defineEmits(['toggle']);
const toggled = defineModel('toggled',{default: () => false});

const toggle = () => {
  toggled.value = !toggled.value;
  emits('toggle', toggled.value);
}

defineExpose({
  toggled
})
</script>

<template>
  <div class="inline-block leading-none">
    <div @click="toggle"
         class="flex justify-center items-center cursor-pointer select-none w-fit"
    >
      <div v-if="!toggled || !props.labelToggled && !slots.toggled">
        <slot name="default" />
      </div>
      <div v-if="toggled && slots.toggled">
        <slot name="toggled" />
      </div>
      <div v-if="toggled && props.labelToggled && !props.labelToggled">{{ props.labelToggled }}</div>
      <div v-if="props.withChevron">
        <IconChevronDown class="inline-block" :class="{'rotate3D-x-180': toggled}" :style="{fill: 'inherit'}" />
      </div>
    </div>
  </div>
</template>
